@font-face {
  font-family: 'Nimbus';
  src: url('../assets/fonts/NimbusCEZOT.woff2') format('woff2');
}

@font-face {
  font-family: 'Futura Medium';
  src: url('../assets/fonts/FuturaCEZOT-Medium.woff2') format('woff2');
}

html,
body {
  font-family: 'Nimbus', 'Futura Medium', Arial, serif;
  margin: 0;
  height: 100%;
  min-height: 100%;
}

div#root {
  overflow-y: auto;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
